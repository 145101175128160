.ServicesDrawer {
  width: calc(100vw - 270px);
  height: 100% !important;

  &__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 20px 20px 40px 20px;

    &__CloseButton {
      background-color: black !important;
      width: 50px;
      height: 50px;

      &__Icon {
        color: white;
        font-size: 26px !important;
        transition: 0.3s !important;
      }

      &:hover {
        .ServicesDrawer__Wrapper__CloseButton__Icon {
          font-size: 30px !important;
          transform: rotate(90deg);
        }
      }
    }

    &__Content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &__Title {
        font-size: 2.6rem;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        letter-spacing: 5px;
        margin-bottom: 70px;
      }

      &__Features {
        max-width: 640px;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 40px;
        column-gap: 20px;
        padding-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 920px) {
    width: calc(100vw - 260px);
  }
}

.Services {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  &__Title {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    letter-spacing: 5px;
    margin: 60px 0;
    padding: 0 20px;
  }

  &__Content {
    display: flex;
    margin-top: 12px;
    padding: 0 20px;
    max-width: 1200px;

    &__Image {
      width: 30vw;
      min-width: 300px;
      margin-right: 40px;
      user-select: none;
      pointer-events: none;
    }

    &__Features {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 40px;
      column-gap: 40px;
    }
  }

  @media screen and (max-width: 920px) {
    &__Title {
      font-size: 2.6rem;
      margin-top: 20px;
    }

    &__Content {
      flex-direction: column;
      align-items: center;
      max-width: 800px;

      &__Image {
        margin-right: 0;
        margin-bottom: 60px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    &__Content {
      flex-direction: column;
      align-items: center;

      &__Image {
        margin-right: 0;
        margin-bottom: 60px;
      }

      &__Features {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;
        column-gap: 0;
      }
    }
  }
}
