.PrivacyPolicy {
  display: flex;
  justify-content: center;

  &__Wrapper {
    max-width: 1000px;
    padding: 40px 20px;
    font-size: 1.4rem;
    line-height: 20px;

    h1 {
      font-size: 2.3rem;
      margin-bottom: 34px;
    }

    h2 {
      font-size: 1.9rem;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 1.7rem;
      margin-top: 20px;
      margin-bottom: 12px;
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 5px;
    }

    a {
      font-size: 1.4rem;
    }
  }
}
