.LandingPage {
  background-size: cover;
  background-position: center center;
  background-image: url("../../assets/images/LandingPage/landing-background.jpg");
  height: 100vh;
  overflow-x: hidden;

  &__Top {
    position: absolute;
    color: white;
    top: 20px;
    right: 28px;
    display: flex;
    z-index: 10;

    &__Text {
      font-size: 1.7rem;
    }

    &__Group {
      cursor: pointer !important;
      font-size: 1.7rem;
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      margin-left: 40px;

      &__Icon {
        font-size: 2.2rem !important;
        margin-right: 8px;
      }
    }
  }

  &__Wrapper {
    width: calc(100vw - 290px);
    height: 100%;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    &__Title {
      font-size: 5rem;
      text-align: center;
      font-weight: bold;
      font-family: "DM Serif Display", serif;
      color: white;
    }

    &__Subtitle {
      font-size: 2.4rem;
      max-width: 640px;
      text-align: center;
      color: white;
      margin-top: 20px;
    }

    &__Empty {
      height: 20px;
    }

    &__Button {
      padding: 16px 32px;
      font-size: 2.1rem;
      font-weight: 600;
      margin-top: 40px;
      cursor: pointer;
      text-decoration: none;
      background-color: #d4b062;
      color: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: 0.3s;

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }
  }

  &__Arrow {
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1160px) {
    &__Top {
      flex-direction: column-reverse;
      align-items: flex-end;
      right: 20px;
      top: 12px;

      &__Text {
        margin-top: 6px;
      }

      &__Group {
        margin: 4px 0 0 0;
        align-items: flex-start;

        &__Icon {
          margin-right: 4px;
        }

        &:first-child {
          margin-right: 18px;
        }
      }
    }

    &__Wrapper {
      &__Text {
        max-width: 100px;
      }

      &__Title {
        font-size: 3.6rem;
      }

      &__Subtitle {
        font-size: 1.9rem;
        max-width: 500px;
      }

      &__Empty {
        height: 12px;
      }

      &__Button {
        font-size: 1.9rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__Top {
      top: 10px;
      right: 24px;
      flex-direction: column-reverse;
      max-width: 70%;

      &__Text {
        visibility: hidden;
      }

      &__Group {
        &__Icon {
          color: #f3cb75;
          font-size: 34px !important;
        }

        &:first-child {
          margin: 4px 10px 0 0;
        }
      }
    }

    &__Wrapper {
      width: 100%;
      pointer-events: none;

      &__Title {
        font-size: 3.2rem;
        line-height: 1.3;
        margin-top: 24px;
      }

      &__Subtitle {
        font-size: 1.7rem;
        max-width: 500px;
        margin-top: 16px;
      }

      &__Empty {
        height: 4px;
      }

      &__Button {
        font-size: 1.8rem;
        text-align: center;
        padding: 10px 26px;
        margin-top: 24px;
        pointer-events: visible;
      }
    }

    &__Arrow {
      position: absolute;
      bottom: 36px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
