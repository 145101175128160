.Steps {
  background-image: url("../../assets/images/Steps/steps.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;

  &__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 0 20px;

    &__Empty {
      background-color: #000000;
      width: 100%;
      height: 1px;
    }

    &__Title {
      font-size: 3.9rem;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      margin: 90px 0 80px 0;
    }

    &__Main {
      display: flex;

      &__Step {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 50px;

        &__Number {
          font-size: 3.9rem;
          font-weight: 600;
        }

        &__Text {
          font-size: 2rem;
          text-transform: uppercase;
          font-weight: 600;
          margin-top: 8px;
          text-align: center;
        }
      }
    }

    &__CallButton {
      padding: 16px 32px;
      font-size: 2.1rem;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
      background-color: #d4b062;
      color: white;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: 0.3s;
      margin: 84px 0 90px 0;

      &:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
          0 10px 10px rgba(0, 0, 0, 0.22);
      }
    }
  }

  @media screen and (max-width: 1080px) {
    &__Wrapper {
      &__Title {
        font-size: 2.4rem;
        text-align: center;
        margin: 60px 0 50px 0;
      }

      &__Main {
        flex-direction: column;

        &__Step {
          margin: 12px 0;

          &__Number {
            font-size: 2.6rem;
          }

          &__Text {
            font-size: 1.8rem;
            line-height: 1.4;
            padding: 0 20px;
          }
        }
      }

      &__CallButton {
        font-size: 1.8rem;
        text-align: center;
        padding: 10px 26px;
      }
    }
  }
}
