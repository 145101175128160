.CustomButton {
  height: 54px;
  text-transform: uppercase;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  background-color: #000 !important;
  color: white !important;
  letter-spacing: 0.5px;
  box-shadow: 1px 1px 4px 0px rgba(99, 99, 99, 1) !important;
  box-shadow: 1px 1px 4px 0px rgba(99, 99, 99, 1) !important;
  transition: 0.3s !important;

  &:hover {
    background-color: rgb(44, 44, 44) !important;
    box-shadow: 1px 1px 4px 0px rgba(99, 99, 99, 1) !important;
    box-shadow: 3px 3px 6px 0px rgba(145, 145, 145, 1) !important;
  }

  &:disabled {
    background-color: rgba($color: black, $alpha: 0.2) !important;
  }

  &__Progressbar {
    color: white !important;
    padding: 6px;
  }
}
