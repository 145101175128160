.Header {
  height: 76px;
  position: relative;

  &__MenuWrapper {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0 0 20px;

    &__LogoWrapper {
      cursor: pointer;

      &__Logo {
        filter: brightness(0) invert(1);
        height: 80px;
        margin: 12px 0 0 16px;
        user-select: none;
        pointer-events: none;
      }
    }

    &__MenuButton {
      &__Icon {
        font-size: 44px !important;
      }
    }
  }

  &__DrawerParent {
    z-index: 10 !important;
    pointer-events: none;
    background-color: rgba($color: black, $alpha: 0.5) !important;
    position: absolute !important;
  }

  &__Drawer {
    max-width: 270px;
    width: 60vw;
    overflow-x: hidden;

    @media screen and (max-width: 400px) {
      width: 70vw;
    }

    &__List {
      padding-top: 0px !important;

      &__ListTitle {
        background-color: rgba($color: black, $alpha: 0.1) !important;
        height: 100px;

        &__Text {
          font-size: 2.8rem !important;
          font-weight: 600 !important;
          text-transform: uppercase !important;
          margin: 12px 12px 12px 24px !important;
          letter-spacing: 3px !important;
          color: white !important;
          width: min-content !important;
          cursor: pointer;
        }

        &__Wrapper {
          pointer-events: all !important;
          cursor: pointer;

          &__Logo {
            filter: brightness(0) invert(1) !important;
            width: 180px !important;
            margin: 12px 12px 12px 20px !important;
            user-select: none;
            pointer-events: none;
          }
        }
      }

      &__ListItemText {
        color: white;
        padding-left: 12px !important;
        font-size: 1.5rem !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
        margin: 0 10px !important;
        padding: 14px 10px !important;
        letter-spacing: 3px !important;
        pointer-events: all !important;
        transition: 0.3s;

        &:hover {
          color: #d4b062;
        }
      }
    }
  }

  &__Background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__Wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 80px;

    &__LogoWrapper {
      cursor: pointer;

      &__Title {
        font-size: 2.8rem;
        color: white;
        cursor: pointer;
        letter-spacing: 4px;
        transition: 0.3s;

        &:hover {
          color: #d4b062;
        }
      }

      &__Logo {
        filter: brightness(0) invert(1);
        height: 56px;
        margin-top: 2px;
        user-select: none;
        pointer-events: none;
      }

      &__Title-Bright {
        font-size: 2.8rem;
        color: white;
        cursor: pointer;
        letter-spacing: 4px;
        transition: 0.3s;

        &:hover {
          color: black;
        }
      }
    }

    &__Actions {
      display: flex;

      &__PageName {
        font-size: 1.9rem;
        text-transform: uppercase;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        letter-spacing: 1px;
        margin-left: 48px;

        &:hover {
          color: #d4b062;
        }
      }

      &__PageName-Bright {
        font-size: 1.9rem;
        text-transform: uppercase;
        color: white;
        cursor: pointer;
        transition: 0.3s;
        letter-spacing: 1px;
        margin-left: 48px;

        &:hover {
          color: black;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    background-color: black;
    margin-top: -10px;
    height: 72px;

    &__MenuWrapper {
      padding: 8px 0 0 8px;

      &__LogoWrapper__Logo {
        width: 170px;
        height: unset;
        filter: brightness(0) invert(1) !important;
      }

      &__MenuButton {
        margin-top: 0 !important;
      }
    }

    &__DrawerParent {
      background-color: white !important;
    }

    &__Drawer {
      &__List {
        &__ListTitle {
          background-color: black !important;
          margin-bottom: 8px;
        }

        &__ListItemText {
          color: black;
        }
      }
    }
  }
}
