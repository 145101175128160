.Arrow {
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8px;
  cursor: pointer;

  &__Icon {
    height: 30px;
    width: 30px;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    transition: 0.3s;
    margin-top: 0;
  }

  &:hover {
    .Arrow__Icon {
      margin-top: 8px;
    }
  }
}
