.FleetItem {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  padding: 0 20px;
  margin-bottom: 50px;

  &__Content {
    display: flex;
    margin-top: 60px;

    &__Images {
      display: flex;
      flex-direction: column;

      &__MainImage {
        object-fit: contain;
      }

      &__MainImage-Hover {
        object-fit: contain;
        display: none;
      }

      &__MoreImages {
        display: flex;
        margin-top: 12px;

        &__Container {
          width: 70px;
          height: 70px;
          border: 2px solid black;
          border-radius: 6px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;

          &--active {
            width: 70px;
            height: 70px;
            border: 1px solid #ccc;
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;

            &:last-child {
              margin-right: 0;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &__Image {
            object-fit: cover;
            object-position: center center;
            width: 68px;
            height: 68px;
            border-radius: 8px;
            user-select: none;
            pointer-events: none;
            font-size: 0;
            padding: 4px;
          }
        }
      }
    }

    &__TextWrapper {
      max-width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__Title {
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: bold;
        color: black;
        letter-spacing: 5px;
        margin-bottom: 24px;
      }

      &__Text {
        font-size: 1.6rem;
        line-height: 22px;
        color: black;
      }
    }
  }

  @media screen and (max-width: 920px) {
    &__Content {
      flex-direction: column-reverse !important;
      align-items: center;

      &__Images {
        &__MainImage {
          width: unset;
          width: 520px;
        }

        &__MoreImages {
          justify-content: center;
        }
      }

      &__TextWrapper {
        margin: 0 0 32px 0 !important;
        padding-top: 0;
        max-width: 360px;

        &__Title {
          text-align: center;
          font-size: 2.4rem;
          letter-spacing: 3px;
        }

        &__Text {
          text-align: center;
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    padding: 0;

    &__Content {
      flex-direction: column-reverse !important;
      align-items: center;
      margin-top: 20px;

      &__Images {
        align-items: center !important;
        justify-content: center !important;
        max-width: 80vw;

        &__MainImage {
          //width: 100% !important;
        }
      }

      &__TextWrapper {
        max-width: none;
        width: 90%;

        &__Title {
          font-size: 2.2rem;
          text-align: center;
        }

        &__Text {
          font-size: 1.55rem;
          text-align: center;
        }
      }
    }

    &:first-child {
      margin-top: 40px;
    }
  }
}
