.Fleet {
  background-color: #f2f2f2;

  &__LandingPage {
    background-size: cover;
    background-position: center center;
    background-image: url("../../assets/images/Fleet/fleet-background.jpg");
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__SideWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      padding: 0 20px;

      &__SecondaryText {
        font-size: 2.2rem;
        color: white;
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }

  &__Wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    &__LandingPage {
      background-position: 72% center;

      &__SideWrapper {
        margin-bottom: 80px;
      }
    }
  }
}
