.CustomInputField {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__Label {
    font-size: 1.3rem;
    font-weight: 600;
    color: #000;
    letter-spacing: 1px;
    transition: 0.2s;
  }

  &__InputText {
    font-size: 1.5rem !important;
  }

  &__InputHelper {
    font-size: 1.5rem !important;
  }
}
