.Footer {
  overflow-x: hidden;
  background-color: #000;

  &__Wrapper {
    margin: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__Content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: 30px;
      column-gap: 40px;
      max-width: 1180px;
      width: 100%;

      @media screen and (max-width: 1120px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      &__Info {
        max-width: 190px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 20;

        &__BigTitle {
          height: 44px;
          font-size: 2.4rem;
          color: white;
          cursor: pointer;
          transition: 0.3s;
          letter-spacing: 2px;
          margin-bottom: 24px;

          &:hover {
            font-size: 2.5rem;
            color: #d4b062;
          }
        }

        &__Title {
          font-size: 1.5rem;
          color: white;
          margin-bottom: 14px;
          z-index: 20;
        }

        &__Text {
          font-size: 1.4rem;
          line-height: 20px;
          color: grey;
          margin: 5px 0;
        }

        &__LinkText {
          font-size: 1.4rem;
          line-height: 20px;
          cursor: pointer;
          text-decoration: none;
          outline: none;
          color: grey;
          transition: 0.3s;
          margin: 5px 0;

          &:hover {
            margin-left: 4px;
            color: #d4b062;
          }
        }
      }
    }

    &__Copyright {
      max-width: 1180px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 60px;
      z-index: 20;

      &__Text {
        font-size: 1.4rem;
        line-height: 22px;
        color: grey;
      }

      &__Developed {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 22px;
        color: grey;
        margin-top: 8px;

        &__Link {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: grey;
          text-decoration: none;

          &__Logo {
            height: 40px !important;
            margin: 0 8px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    &__Wrapper {
      &__Content {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    &__Wrapper {
      margin: 50px 20px 50px 40px;

      &__Content {
        grid-template-columns: 1fr 1fr;

        &__Info {
          max-width: 220px;

          &__BigTitle {
            font-size: 2.6rem;
            margin-bottom: 56px;
            max-width: 140px;

            &:hover {
              font-size: 2.7rem;
            }
          }

          &__Title {
            font-size: 1.6rem;
            margin-bottom: 14px;
          }

          &__Text {
            font-size: 1.5rem;
            line-height: 20px;
            margin: 5px 0;
          }

          &__LinkText {
            font-size: 1.5rem;
            line-height: 20px;
            margin: 5px 0;
          }
        }
      }

      &__Copyright {
        margin-top: 50px;

        &__Text {
          font-size: 1.5rem;
        }
      }
    }
  }
}
