.About {
  min-height: 100vh;
  overflow-x: hidden;

  &__Wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    &__Content {
      max-width: 1200px;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 30px 30px 30px;

      &__Title {
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        letter-spacing: 5px;
        margin: 70px 20px;
      }

      &__Info {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 30px;

        &__Image {
          max-width: 600px;
          width: 100%;
          user-select: none;
          pointer-events: none;
          margin-right: 60px;
        }

        &__Text {
          font-size: 1.65rem;
          line-height: 24px;
          width: 100%;
        }
      }

      @media screen and (max-width: 1120px) {
        &__Info {
          &__Image {
            margin-right: 30px;
          }
        }
      }

      @media screen and (max-width: 920px) {
        &__Info {
          flex-direction: column;
          align-items: center;
          margin-top: 0;

          &__Image {
            width: unset;
            width: 66vw;
            margin-right: 0;
          }

          &__Text {
            margin-top: 30px;
            width: 60vw;
          }
        }
      }

      @media screen and (max-width: 540px) {
        &__Info {
          &__Image {
            width: 100%;
          }

          &__Text {
            padding: 30px 16px 0 16px;
            text-align: justify;
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__Wrapper__Content {
      margin-bottom: 12px;

      &__Title {
        font-size: 2.6rem;
        margin: 70px 20px 60px 20px;
      }

      &__Info {
        &__Text {
          font-size: 1.5rem;
        }
      }
    }
  }
}
