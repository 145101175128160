.ServiceItem {
  display: flex;
  max-width: 640px;

  &__Icon {
    height: 52px;
    user-select: none;
    pointer-events: none;
  }

  &__Texts {
    display: flex;
    flex-direction: column;
    margin-left: 24px;

    &__Title {
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 8px;
    }

    &__Text {
      font-size: 1.5rem;
      color: grey;
    }
  }
}
