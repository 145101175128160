.Reservation {
  min-height: 100vh;
  overflow-x: hidden;

  &__Wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    &__Content {
      max-width: 1000px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 30px 60px 30px;

      &__Title {
        font-size: 5rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 3px;
        margin: 60px 20px 34px 20px;
      }

      &__Contact {
        font-size: 1.8rem;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 4px;

        &__Link {
          color: black;
          cursor: pointer;
          text-decoration: none;
          transition: 0.2s;

          &:hover {
            color: #d4b062;
          }
        }
      }

      &__Subtitle {
        font-size: 1.8rem;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 60px;
      }

      &__Form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__Row {
          display: flex;
          width: 100%;
        }
      }
    }
  }

  &__Dialog {
    &__Title {
      font-size: 2rem !important;
    }

    &__Content {
      max-width: 500px;
    }

    &__CloseButton {
      color: #000 !important;

      &__Text {
        font-size: 1.5rem !important;
        color: #000 !important;
        font-weight: 500 !important;
        text-transform: none !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__Wrapper__Content {
      padding: 0 34px 60px 34px;

      &__Title {
        font-size: 3.8rem;
        letter-spacing: 2px;
        margin: 60px 24px 34px 24px;
      }

      &__Contact {
        font-size: 1.7rem;
        margin-bottom: 8px;
      }

      &__Subtitle {
        font-size: 1.7rem;
      }

      &__Form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__Row {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
